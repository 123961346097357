<template>
  <b-container fluid class="mb-2 ">
    <b-row fluid class="px-1 mx-1 text-center rounded-lg">
      <b-col>
        <h3>Advanced Search</h3>
        <hr />
        <div class="flex flex-row ">
          <h5
            class="p-1 border rounded-full border-primary text-primary text-bold"
          >
            Step 1
          </h5>
        </div>
        <div class="flex flex-row items-center justify-center mb-3">
          <b-form-group
            v-slot="{ ariaDescribedby }"
            label="Choose what you would like to filter by"
          >
            <b-form-radio-group
              id="btn-radios-2"
              v-model="filterByKey"
              :aria-describedby="ariaDescribedby"
              button-variant="outline-primary"
              size="lg"
              name="radio-btn-outline"
              buttons
              @change="updateKey()"
            >
              <b-form-radio
                v-for="(mValue, mKey) in allFilters"
                :key="mKey"
                :value="mKey"
                >{{ getModelName(mKey) }}</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </div>
        <hr />
        <b-row>
          <b-col class="mt-2">
            <div class="flex flex-row ">
              <h5
                class="p-1 border rounded-full border-primary text-primary text-bold"
              >
                Step 2
              </h5>
            </div>
            <b-row class="mt-1">
              <b-col v-for="(tValue, tKey) in allFilters[filterBy]" :key="tKey">
                <div>
                  <b-col>
                    <div class="flex flex-row">
                      <label class="font-bold"
                        >Filter By {{ getFilterType(tKey) }}:</label
                      >
                    </div>
                    <div class="flex flex-row">
                      <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                          id="radio-group-2"
                          v-model="typeValue"
                          :aria-describedby="ariaDescribedby"
                          name="radio-sub-component"
                        >
                          <b-form-radio
                            v-for="(item, index) in tValue"
                            :key="getKeys(item)"
                            :value="{ fType: tKey, fIndex: index }"
                            class="w-full"
                            >{{ item[getKeys(item)].name }}</b-form-radio
                          >
                        </b-form-radio-group>
                      </b-form-group>
                    </div>
                  </b-col>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="5">
        <div class="invisible">
          <h3>Advanced Search</h3>
        </div>
        <hr />
        <div
          v-if="typeValue.fType === 'text'"
          class="flex flex-row p-1 bg-gray-100 border rounded-lg"
        >
          <b-col>
            <div class="flex flex-row justify-between mb-2">
              <div>
                <h5
                  class="p-1 border rounded-full border-primary text-primary text-bold"
                >
                  Step 3
                </h5>
              </div>
              <div class="w-48 p-1 mt-1 bg-gray-200 border rounded-lg">
                <label>
                  <h5>
                    <span class="font-bold">Exclude</span> or
                    <span class="font-bold">Filter</span>
                  </h5></label
                >
                <b-form-group>
                  <b-form-checkbox v-model="filterExclude" switch>{{
                    filterExclude ? "Filter" : "Exclude"
                  }}</b-form-checkbox>
                </b-form-group>
              </div>
            </div>
            <div class="flex flex-col items-start w-full">
              <h4 class="mt-1 text-gray-700">
                {{ filterExclude ? "Filter" : "Exclude" }}
                {{ getModelName(filterBy)
                }}{{ filterBy === "notes" ? "" : "s" }} by:
              </h4>
              <h4 class="flex self-end text-gray-700">
                <span
                  class="px-2 ml-2 text-gray-600 bg-white border rounded-lg"
                  >{{ getFilter().filter.name }}</span
                >
              </h4>
            </div>
            <div class="mb-3"><hr /></div>
            <div class="flex flex-col items-center justify-center w-full ">
              <div class="flex flex-row w-full max-w-sm space-x-3">
                <div class="flex flex-col w-full ">
                  <b-form-group>
                    <b-input-group size="lg">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="search"
                        v-model="textFilter"
                        placeholder="Text Search"
                        debounce="500"
                      />
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
              <div class="flex flex-row items-center justify-end w-full mt-3">
                <b-button variant="primary" @click="addTextFilter()">
                  Add Filter
                </b-button>
              </div>
            </div>
          </b-col>
        </div>
        <div
          v-if="typeValue.fType === 'date'"
          class="flex flex-row p-1 bg-gray-100 border rounded-lg"
        >
          <b-col>
            <div class="flex flex-row justify-between mb-2">
              <div>
                <h5
                  class="p-1 border rounded-full border-primary text-primary text-bold"
                >
                  Step 3
                </h5>
              </div>
              <div class="w-48 p-1 mt-1 bg-gray-200 border rounded-lg">
                <label>
                  <h5>
                    <span class="font-bold">Exclude</span> or
                    <span class="font-bold">Filter</span>
                  </h5></label
                >
                <b-form-group>
                  <b-form-checkbox v-model="filterExclude" switch>{{
                    filterExclude ? "Filter" : "Exclude"
                  }}</b-form-checkbox>
                </b-form-group>
              </div>
            </div>
            <div class="flex flex-col items-start w-full">
              <h4 class="mt-1 text-gray-700">
                {{ filterExclude ? "Filter" : "Exclude" }}
                {{ getModelName(filterBy)
                }}{{ filterBy === "notes" ? "" : "s" }} by:
              </h4>
              <h4 class="flex self-end text-gray-700">
                <span
                  class="px-2 ml-2 text-gray-600 bg-white border rounded-lg"
                  >{{ getFilter().filter.name }}</span
                >
              </h4>
            </div>
            <div class="mb-3"><hr /></div>

            <div class="flex flex-col items-center justify-center w-full ">
              <div class="flex flex-row max-w-sm space-x-1">
                <div class="mr-1">
                  <label
                    >{{ getFilter().filter.name }}
                    <span class="ml-1 font-bold">FROM</span></label
                  >
                  <b-input-group
                    label-for="From-input-mailed-date"
                    label-cols-lg="3"
                    label-align-lg="right"
                    label-size="lg"
                    class="mb-0"
                  >
                    <b-form-input
                      id="from-input-mailed-date"
                      v-model="date.from"
                      size="sm"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      autocomplete="off"
                      show-decade-nav
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="date.from"
                        size="sm"
                        show-decade-nav
                        button-only
                        right
                        locale="en-US"
                        aria-controls="from-input-mailed-date"
                        ><template #button-content>
                          <feather-icon icon="CalendarIcon"/></template
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div>
                  <label
                    >{{ getFilter().filter.name }}
                    <span class="ml-1 font-bold">TO</span></label
                  >
                  <b-input-group
                    label-for="date-to"
                    label-cols-lg="3"
                    label-align-lg="right"
                    label-size="lg"
                    class="mb-0"
                  >
                    <b-form-input
                      id="to-input-mailed-date"
                      v-model="date.to"
                      size="sm"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      autocomplete="off"
                      show-decade-nav
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="date.to"
                        size="sm"
                        show-decade-nav
                        button-only
                        right
                        locale="en-US"
                        aria-controls="to-input-mailed-date"
                        ><template #button-content>
                          <feather-icon icon="CalendarIcon"/></template
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>
              <div class="flex flex-row items-center justify-end w-full mt-3">
                <b-button variant="primary" @click="addDateFilter()">
                  Add Filter
                </b-button>
              </div>
            </div>
          </b-col>
        </div>
        <div
          v-if="typeValue.fType === 'options'"
          class="flex flex-row p-1 bg-gray-100 border rounded-lg"
        >
          <b-col>
            <div class="flex flex-row justify-between mb-2">
              <div>
                <h5
                  class="p-1 border rounded-full border-primary text-primary text-bold"
                >
                  Step 3
                </h5>
              </div>
              <div class="w-48 p-1 mt-1 bg-gray-200 border rounded-lg">
                <label>
                  <h5>
                    <span class="font-bold">Exclude</span> or
                    <span class="font-bold">Filter</span>
                  </h5></label
                >
                <b-form-group>
                  <b-form-checkbox v-model="filterExclude" switch>{{
                    filterExclude ? "Filter" : "Exclude"
                  }}</b-form-checkbox>
                </b-form-group>
              </div>
            </div>
            <div class="flex flex-col items-start w-full">
              <h4 class="mt-1 text-gray-700">
                {{ filterExclude ? "Filter" : "Exclude" }}
                {{ getModelName(filterBy)
                }}{{ filterBy === "notes" ? "" : "s" }} by:
              </h4>
              <h4 class="flex self-end text-gray-700">
                <span
                  class="px-2 ml-2 text-gray-600 bg-white border rounded-lg"
                  >{{ getFilter().filter.name }}</span
                >
              </h4>
            </div>
            <div class="mb-3"><hr /></div>
            <div class="flex flex-col items-center justify-center w-full ">
              <div class="flex flex-row w-full max-w-sm space-x-3">
                <div class="flex flex-col w-full ">
                  <v-select
                    id="filters"
                    v-model="optionSelect"
                    class="bg-white"
                    :options="getFilter()['filter']['options']"
                    label="details"
                    placeholder="Select One Option"
                  >
                  </v-select>
                </div>
              </div>
              <div class="flex flex-row items-center justify-end w-full mt-3">
                <b-button variant="primary" @click="addOptionFilter()">
                  Add Filter
                </b-button>
              </div>
            </div>
          </b-col>
        </div>
        <div
          v-if="typeValue.fType === 'bool'"
          class="flex flex-row p-1 bg-gray-100 border rounded-lg"
        >
          <b-col>
            <div class="flex flex-row justify-between mb-2">
              <div>
                <h5
                  class="p-1 border rounded-full border-primary text-primary text-bold"
                >
                  Step 3
                </h5>
              </div>
              <div class="w-48 p-1 mt-1 bg-gray-200 border rounded-lg">
                <label>
                  <h5>
                    <span class="font-bold">Exclude</span> or
                    <span class="font-bold">Filter</span>
                  </h5></label
                >
                <b-form-group>
                  <b-form-checkbox v-model="filterExclude" switch>{{
                    filterExclude ? "Filter" : "Exclude"
                  }}</b-form-checkbox>
                </b-form-group>
              </div>
            </div>
            <div class="flex flex-col items-start w-full">
              <h4 class="mt-1 text-gray-700">
                {{ filterExclude ? "Filter" : "Exclude" }}
                {{ getModelName(filterBy)
                }}{{ filterBy === "notes" ? "" : "s" }} by:
              </h4>
              <h4 class="flex self-end text-gray-700">
                <span
                  class="px-2 ml-2 text-gray-600 bg-white border rounded-lg"
                  >{{ getFilter().filter.name }}</span
                >
              </h4>
            </div>
            <div class="mb-3"><hr /></div>
            <div class="flex flex-col items-center justify-center w-full ">
              <div class="flex flex-row w-full max-w-sm space-x-3">
                <div class="flex flex-col w-full ">
                  <b-form-group v-slot="{ ariaDescribedby }" size="lg">
                    <b-form-radio-group
                      id="radio-group-2"
                      v-model="boolFilter"
                      :aria-describedby="ariaDescribedby"
                      name="radio-sub-component"
                    >
                      <b-form-radio key="yes" :value="true">
                        Yes
                      </b-form-radio>
                      <b-form-radio key="No" :value="true">
                        no
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="flex flex-row items-center justify-end w-full mt-3">
                <b-button variant="primary" @click="addBoolFilter()">
                  Add Filter
                </b-button>
              </div>
            </div>
          </b-col>
        </div>
        <div
          v-if="typeValue.fType === 'integer'"
          class="flex flex-row p-1 bg-gray-100 border rounded-lg"
        >
          <b-col>
            <div class="flex flex-row justify-between mb-2">
              <div>
                <h5
                  class="p-1 border rounded-full border-primary text-primary text-bold"
                >
                  Step 3
                </h5>
              </div>
              <div class="w-48 p-1 mt-1 bg-gray-200 border rounded-lg">
                <label>
                  <h5>
                    <span class="font-bold">Exclude</span> or
                    <span class="font-bold">Filter</span>
                  </h5></label
                >
                <b-form-group>
                  <b-form-checkbox v-model="filterExclude" switch>{{
                    filterExclude ? "Filter" : "Exclude"
                  }}</b-form-checkbox>
                </b-form-group>
              </div>
            </div>
            <div class="flex flex-col items-start w-full">
              <h4 class="mt-1 text-gray-700">
                {{ filterExclude ? "Filter" : "Exclude" }}
                {{ getModelName(filterBy)
                }}{{ filterBy === "notes" ? "" : "s" }} by:
              </h4>
              <h4 class="flex self-end text-gray-700">
                <span
                  class="px-2 ml-2 text-gray-600 bg-white border rounded-lg"
                  >{{ getFilter().filter.name }}</span
                >
              </h4>
            </div>
            <div class="mb-3"><hr /></div>
            <div class="flex flex-col items-center justify-center w-full ">
              <div class="flex flex-row w-full max-w-sm space-x-3">
                <div class="flex flex-row justify-around w-full ">
                  <div
                    class="flex flex-col items-start justify-center w-full mr-3"
                  >
                    <label for="range-2">Amount From</label>

                    <b-form-input
                      id="range-2"
                      v-model="integerFilter.from"
                      type="range"
                      :min="fromMin(amount)"
                      :max="fromMax(amount)"
                      step="500.0"
                    ></b-form-input>
                    <div class="">
                      Amount: {{ currencyFormat(integerFilter.from) }}
                    </div>
                  </div>
                  <div class="flex flex-col items-start justify-center w-full">
                    <label for="range-2">Amount To</label>

                    <b-form-input
                      id="range-2"
                      v-model="integerFilter.to"
                      type="range"
                      :min="toMin(amount)"
                      :max="toMax(amount)"
                      step="500.0"
                    ></b-form-input>
                    <div class="">
                      Amount: {{ currencyFormat(integerFilter.to) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-row items-center justify-end w-full mt-3">
                <b-button variant="primary" @click="addIntegerFilter()">
                  Add Filter
                </b-button>
              </div>
            </div>
          </b-col>
        </div>

        <div class="py-2 mt-10 border rounded-lg ">
          <div class="flex flex-row w-full">
            <div class="flex flex-col justify-start w-1/4 ml-4">
              <h5
                class="p-1 border rounded-full border-primary text-primary text-bold"
              >
                Step 4
              </h5>
            </div>
            <div class="flex flex-col items-center justify-center w-3/4 ">
              <b-button
                size="lg"
                variant="primary mt-10 w-64"
                @click="updateFilters()"
                >SEARCH</b-button
              >
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-button class="invisible" />
    <div class="flex flex-col items-center justify-end w-full ">
      <div class="w-full mx-1 mb-1 rounded-lg">
        <label> Selected Filters </label>
        <v-select
          id="filters"
          v-model="select"
          multiple
          class="bg-white"
          label="details"
          placeholder="No Filters Applied"
        >
        </v-select>
      </div>
    </div>
  </b-container>
</template>



<script>
// import SearchFilingsFilterCheckbox from "./SearchFilingsFilterCheckbox"
import vSelect from "vue-select"
import { mapGetters } from "vuex"

import {
  BButton,
  BContainer,
  BCol,
  BRow,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormDatepicker,
  BFormCheckbox
} from "bootstrap-vue"

export default {
  components: {
    BButton,
    BContainer,
    BCol,
    BRow,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    vSelect,
    BFormCheckbox,

    BFormDatepicker
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    basePerPage: {
      type: Number,
      default: 0
    },
    baseCurrentPage: {
      type: Number,
      default: 0
    },
    baseTotalRows: {
      type: Number,
      default: 0
    },
    basePageOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      perPage: this.basePerPage,
      currentPage: this.baseCurrentPage,
      totalRows: this.baseTotalRows,
      pageOptions: this.basePageOptions,
      typeValue: { fType: "text", fIndex: 0 },
      filterBy: "case_party",
      filterByKey: "case_party",
      allFilters: this.filter,
      select: [],

      // FILTER OR EXCLUDE
      filterExclude: true,
      // Text Input Filter
      textFilter: "",

      date: { to: "", from: "" },

      // OPTION SELECT

      optionSelect: [],

      // BOOL FILTER

      boolFilter: true,

      integerFilter: { from: 0, to: 0 }
    }
  },
  computed: {
    ...mapGetters({
      newSearchQuery: "notifications/searchQuery"
    })
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    newSearchQuery(newValue) {
      if (newValue) {
        this.searchBarUpdateFilters(newValue)
      }
    }
  },

  mounted() {
    this.mountSearchUpdate()
  },

  methods: {
    mountSearchUpdate() {
      let searchQuery = this.$route.params.search
        ? this.$route.params.search
        : false

      if (searchQuery) {
        const selected = {
          details: `${
            this.filterExclude ? "Filter" : "Exclude"
          } > Quick Search > :: ${searchQuery}`,
          filter: {
            key: "all",
            filter: {
              name: "all",
              related: false,
              options: [],
              model: "all",
              type: "text"
            }
          },

          value: searchQuery,
          filterExclude: this.filterExclude
        }
        this.filterExclude = true

        this.select.push(selected)
      }
    },
    async updateKey() {
      await this.resetFilters()
      this.filterBy = this.filterByKey
    },
    amount() {
      return this.getFilter()["filter"]["options"]
    },
    fromMin(amt) {
      return amt().min
    },
    fromMax() {
      return this.integerFilter.to - 500 <= 0 ? 0 : this.integerFilter.to - 500
    },
    toMin() {
      return this.integerFilter.from - 500 <= 0
        ? 0
        : this.integerFilter.from - 500
    },
    toMax(amt) {
      return amt().max
    },
    currencyFormat(v) {
      return `$${("" + v).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    },
    async addIntegerFilter() {
      let selected = {
        details: `${
          this.filterExclude ? "Filter" : "Exclude"
        } > ${this.getModelName(this.filterBy)} > ${
          this.getFilter()["filter"]["name"]
        } :: ${this.currencyFormat(
          this.integerFilter.from
        )} - ${this.currencyFormat(this.integerFilter.to)}`,
        filter: this.getFilter(),
        value: this.integerFilter,
        filterExclude: this.filterExclude
      }

      await this.select.push(selected)
      this.resetFilters()
    },
    async addBoolFilter() {
      let selected = {
        details: `${
          this.filterExclude ? "Filter" : "Exclude"
        } > ${this.getModelName(this.filterBy)} > ${
          this.getFilter()["filter"]["name"]
        } :: ${this.boolFilter ? "Yes" : "No"}`,
        filter: this.getFilter(),
        value: this.boolFilter,
        filterExclude: this.filterExclude
      }

      await this.select.push(selected)
      this.resetFilters()
    },
    async addTextFilter() {
      let selected = {
        details: `${
          this.filterExclude ? "Filter" : "Exclude"
        } > ${this.getModelName(this.filterBy)} > ${
          this.getFilter()["filter"]["name"]
        } :: ${this.textFilter}`,
        filter: this.getFilter(),
        value: this.textFilter,
        filterExclude: this.filterExclude
      }

      await this.select.push(selected)
      this.resetFilters()
    },
    async addDateFilter() {
      let dF = this.date.from
      let dT = this.date.to
      let selected = {
        details: `${
          this.filterExclude ? "Filter" : "Exclude"
        } > ${this.getModelName(this.filterBy)} > ${
          this.getFilter()["filter"]["name"]
        } :: ${dF} - ${dT}`,
        filter: this.getFilter(),
        value: this.date,
        filterExclude: this.filterExclude
      }

      await this.select.push(selected)
      this.resetFilters()
    },
    async addOptionFilter() {
      let selected = {
        details: `${
          this.filterExclude ? "Filter" : "Exclude"
        } > ${this.getModelName(this.filterBy)} > ${
          this.getFilter()["filter"]["name"]
        } :: ${this.optionSelect}`,
        filter: this.getFilter(),
        value: this.optionSelect,
        filterExclude: this.filterExclude
      }

      await this.select.push(selected)
      this.resetFilters()
    },
    resetFilters() {
      this.typeValue = { fType: "text", fIndex: 0 }
      this.filterExclude = true
      this.textFilter = ""
      this.date = { to: "", from: "" }
      this.optionSelect = []
      this.boolFilter = true
      this.integerFilter = { from: 0, to: 0 }
      return
    },
    getFilter() {
      let filterObject = this.allFilters[this.filterBy][this.typeValue.fType][
        this.typeValue.fIndex
      ]

      let key = this.getKeys(filterObject)
      return { key: key, filter: filterObject[key] }
    },
    getFilterType(value) {
      if (value === "text") {
        return "Text"
      }
      if (value === "date") {
        return "Date"
      }
      if (value === "bool") {
        return "Yes/No"
      }
      if (value === "options") {
        return "Choice"
      }
      if (value === "integer") {
        return "Value"
      }
    },
    getModelName(key) {
      if (key === "case") {
        return "Case"
      }
      if (key === "case_party") {
        return "Defendant"
      }
      if (key === "notepad") {
        return "Notes"
      }
      if (key === "rule_docket") {
        return "Docket"
      }
    },
    getKeys(item) {
      return Object.keys(item)[0]
    },
    updateFilters() {
      this.$emit("updateFilters", this.select)
    },
    searchBarUpdateFilters(searchValue) {
      if (searchValue) {
        const selected = {
          details: `${
            this.filterExclude ? "Filter" : "Exclude"
          } > Quick Search > :: ${searchValue}`,
          filter: {
            key: "all",
            filter: {
              name: "all",
              related: false,
              options: [],
              model: "all",
              type: "text"
            }
          },

          value: searchValue,
          filterExclude: this.filterExclude
        }
        this.filterExclude = true

        this.select.push(selected)
        this.updateFilters()
        this.$store.dispatch("notifications/updateSearchQuery", "")
      }
    }
  }
}
</script>

