<template>
  <div>
    <b-col>
      <div
        class="flex flex-col items-center justify-between w-full lg:flex-row"
      >
        <div
          class="
            w-full
            pt-1
            pl-1
            mb-1
            text-center
            border
            rounded-lg
            border-primary
          "
        >
          <p v-if="totalRows === 0" class="text-sm">
            <span class="ml-1 mr-1 text-xl font-bold invisible">0</span>
            Search to Populate Results
          </p>
          <p v-else class="text-sm">
            <span class="ml-1 mr-1 text-xl font-bold">{{
              kFormatter(totalRows)
            }}</span>
            Total Results
          </p>
        </div>
        <div class="flex flex-col items-center justify-end w-full lg:flex-row">
          <!-- TODO: FIX BUG THAT DOES NOT ADD DATES TO THE QUERY UNLESS A DATE IS CHOSEN
                     THE DEFAULT DATE SHOLD BE SET 
          -->
          <div class="mb-1 mr-2">
            <b-form-group
              label="Sort By"
              label-for="sort-by-select"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sort-by-select"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                </b-form-select>

                <b-form-select
                  v-model="sortDesc"
                  :disabled="!sortBy"
                  size="sm"
                  class="w-2 bg-gray-200"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="mb-1 mr-2">
            <b-form-group
              label="Per page"
              label-for="per-page-select"
              class="mb-0"
            >
              <!-- FIXME: PROBLEM WITH CHOOSING NUMBER PER PAGE -->
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </div>

          <div class="w-64 mr-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>

      <!-- <div class="w-full m-2">
        <div class="float-right">Total Results - {{ totalRows }}</div>
      </div> -->
      <div>
        <b-modal
          id="modal-center"
          v-model="showModal"
          scrollable
          header-class="bg-white"
          hide-footer
          size="xl"
        >
          <!-- <p class="my-4">{{ modalValue.item }}</p> -->
          <total-case-view
            :casedata="casedata[dataIndex]"
            @updateSearchFilings="updateSearchFilings"
            @updateTrackedToggle="updateTrackedToggle"
          >
          </total-case-view>
        </b-modal>
      </div>
      <!-- TODO: ADD STATS SUCH AS TOTAL MAILED ETC. -->
      <!-- TODO: ADD BUSY LOADING ANIMATION TO TABLE -->
      <!-- TODO: FINISH FILTER / SORT. USE FUNCTIONALITY FROM EXPORT TABLES -->
      <!-- TODO: ADD COLUMN CONTAINING MAIL ICON IF MAILING HAS BEEN SENT -->
      <b-row>
        <b-table
          ref="tableMain"
          striped
          hover
          responsive
          :per-page="perPage"
          :busy.sync="isBusy"
          :current-page="currentPage"
          :items="backendCall"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          class="text-sm border rounded-lg"
          @filtered="onFiltered"
        >
          <template #cell(is_tracked)="data">
            <tracked-toggle
              :index="data.index"
              :trackedcase="data.item.case_number"
              :tracked="data.item.is_tracked"
              @updateTrackedToggle="updateTrackedToggle"
            />
          </template>
          <template #cell(case_of_party)="data">
            <b-button variant="secondary" size="sm" @click="modalAction(data)"
              >View Details</b-button
            >
          </template>
          <template #cell(case_number)="data"> {{ data.value }}</template>
          <template #cell(county)="data">
            {{ data.value.toUpperCase() }}
          </template>
        </b-table>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import { kFormatter } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TrackedToggle from "../total-case-view/TrackedToggle.vue";
import TotalCaseView from "../total-case-view/TotalCaseView.vue";
import baseResult from "./BaseResult";
import {
  BTable,
  //BAvatar,
  //BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  //BFormInput,
  //BInputGroupAppend,
  //BInputGroupPrepend,
  BButton,
} from "bootstrap-vue";

export default {
  name: "SearchFilings",
  components: {
    TrackedToggle,
    BTable,
    //BAvatar,
    //BBadge,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,

    //BFormInput,
    //BInputGroupAppend,
    //BInputGroupPrepend,
    BButton,
    TotalCaseView,
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    totalRecords: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      baseData: baseResult,
      initial: true,
      searchQuery: false,
      trackedCases: 0,
      dataIndex: 0,
      showModal: false,
      isBusy: false,
      perPage: 10,
      pageOptions: [3, 5, 8, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "file_date",
      sortDesc: true,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      tableRefresh: false,
      //props: ["casedata"],
      casedata: {},

      fields: [
        {
          key: "is_tracked",
          label: "Tracking",
          sortable: true,
        },
        {
          key: "case_of_party",
          label: "Details",
        },
        {
          key: "case_number",
          label: "Docket",
          sortable: true,
        },
        {
          key: "case_title",
          label: "Title",
          sortable: true,
        },

        {
          key: "county",
          label: "County",
          sortable: true,
        },
        {
          key: "file_date",
          label: "Date Filed",
          sortable: true,
        },
        {
          key: "case_status",
          label: "Status",
          sortable: true,
        },
        {
          key: "case_type",
          label: "Type",
          sortable: true,
        },

        {
          key: "updated_date",
          label: "Last Update",
        },
      ],
    };
  },

  computed: {
    sortOptions() {
      return [
        "defendant",
        "reference",
        "total_address",
        "street",
        "city",
        "state",
        "zip_code",
        "phone",
        "email",
        "employer_info",
        "additional_info",
        "serve_status_date",
        "garnishment_date",
        "answer_date",
        "agreement_date",
        "last_pay_date",
        "bankruptcy_filed_date",
        "serve_status",
        "garnishment_status",
        "is_garnishment",
        "garnishment_answer",
        "is_agreement",
        "is_bankruptcy_filed",
        "is_case_dismissed",
        "county",
        "case_number",
        "judge",
        "plaintiff",
        "case_title",
        "file_date",
        "hearing_date",
        "case_status_date",
        "case_dismiss_date",
        "case_type",
        "case_status",
        "amount",
      ];
      // Create an options list from our fields
      //return this.fields
      //  .filter((f) => f.sortable)
      //  .map((f) => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    isBusy(newValue) {
      this.$emit("busyState", newValue);
    },
    filters: {
      // eslint-disable-next-line no-unused-vars
      handler(newValue) {
        this.$refs.tableMain.refresh();
      },
      deep: true,
    },
  },

  mounted() {
    let searchQuery = this.$route.params.search
      ? this.$route.params.search
      : false;

    if (searchQuery) {
      this.initial = false;
      this.searchQuery = [
        {
          details: `Filter > Quick Search > ALL :: ${searchQuery}`,
          filter: {
            key: "all",
            filter: {
              name: "all",
              related: false,
              options: [],
              model: "all",
              type: "all",
            },
          },
          value: searchQuery,
          filterExclude: true,
        },
      ];
    }
  },
  methods: {
    kFormatter,
    async updateTrackedToggle(variable) {
      const l1 = this;
      l1.tableRefresh = true;

      l1.casedata[variable.index].is_tracked = await variable.tracked;
      l1.casedata[variable.index]._rowVariant = await variable.variant;

      this.$root.$emit("bv::refresh::table", "tableMain");
    }, // eslint-disable-next-line no-unused-vars
    updateSearchFilings(variable) {
      const l1 = this;

      l1.tableRefresh = true;

      this.$root.$emit("bv::refresh::table", "tableMain");
    },
    calculateAmount(amount) {
      return amount < 1 ? amount : "none";
    },
    modalAction(data) {
      this.dataIndex = data.index;

      //this.modalValue = this.casedata[data.index]
      this.showModal = true;
    },
    backendCall(ctx, callback) {
      const l1 = this;

      if (!l1.initial) {
        this.isBusy = true;
        // const params = "?page=" + ctx.currentPage + "&size=" + ctx.perPage

        var x = (ctx.currentPage - 1) * this.perPage;

        if (x < 0) {
          x = x - 1;
        }

        if (!this.searchQuery) {
          this.searchQuery = this.filters;
        }

        if (l1.tableRefresh) {
          callback(l1.casedata);
          l1.tableRefresh = false;
        } else {
          this.$AuthAxios.instance
            .get("/api-access/search/case-search/main/", {
              params: {
                limit: ctx.perPage,
                offset: x,
                data: {
                  filters: this.searchQuery,
                  sortBy: this.sortBy,
                  sortDesc: this.sortDesc,
                },
              },
            })
            .then((res) => {
              l1.searchQuery = false;
              l1.totalRows = res.data.count;
              l1.isBusy = false;
              l1.casedata = res.data.results;
              callback(res.data.results);
              l1.tableRefresh = false;
            })
            .catch((error) => {
              l1.isBusy = false;
              callback([]);
              console.log("verify-user endpoint ERROR ", error);

              if (error.response.data.detail === "Token expired") {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "LOGGED OUT",
                    icon: "EditIcon",
                    variant: "You have been logged out due to inactivity",
                  },
                });
                l1.$store.dispatch("auth/logout", false);
              }
              l1.tableRefresh = false;
              throw new Error({ error: error });
            });
          return null;
        }
      } else {
        l1.initial = false;
        l1.searchQuery = false;
        l1.totalRows = l1.totalRecords;
        l1.isBusy = false;
        l1.casedata = l1.baseData;
        callback(l1.baseData);
        l1.tableRefresh = false;
      }
      // this.fetchData("/some/url" + params)
      //   .then((data) => {
      //     // Pluck the array of items off our axios response
      //     const items = data.items
      //     // Provide the array of items to the callback
      //     callback(items)
      //   })
      //   .catch(() => {
      //     callback([])
      //   })

      // Must return null or undefined to signal b-table that callback is being used
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
