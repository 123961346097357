<template>
  <div class="flex flex-row justify-center w-ful">
    <div v-if="searchFilters" no-body class="max-w-6xl p-1 xl:min-w-6xl">
      <search-filings-content
        :search-filters="searchFilters"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
//import { BCard } from "bootstrap-vue"
import SearchFilingsContent from "../components/search-filings/SearchFilingsContent.vue"

export default {
  components: { SearchFilingsContent },
  data() { 
    return {
      searchFilters: false,
      showStatsModal: false,
      filter: "",
      stats: {
        total_exports: 0,
        total_records_mailed: {
          garnish: 0,
          disposed: 0,
          hearing: 0,
          served: 0,
          filed: 0,
          subtotal: 0
        },
        mailings_over_time: {
          last_30: 0,
          last_60: 0,
          last_90: 0,
          last_12_mos: 0
        }
      },

      contentWidth: this.$store.state.appConfig.layout.contentWidth
    }
  },

  created() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed")
    this.getSearchFilters()
  },

  destroyed() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", this.contentWidth)
  },
  methods: {
    getSearchFilters() {
      this.$AuthAxios.instance
        .get("/api-access/search/get-search-filters/", {})
        .then((res) => {
          this.searchFilters = res.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
