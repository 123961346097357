<template>
  <b-overlay :show="isBusy" rounded="lg" opacity="0.6">
    <div class="flex flex-col w-full">
      <div class="flex flex-col items-center justify-around md:flex-row">
        <b-card class="w-full border rounded-lg shadow-sm">
          <search-filings-table-filters
            v-if="newSearchFilters"
            :filter="newSearchFilters"
            :base-per-page="basePerPage"
            :base-current-page="baseCurrentPage"
            :base-total-rows="baseTotalRows"
            :base-page-options="basePageOptions"
            @searchBarSearch="searchBarSearch"
            @updateFilters="updateFilters"
            v-on="$listeners"
          />
          <search-filings-table-new
            ref="searchFilingsTable"
            :filters="filters"
            :total-records="totalRecords"
            @busyState="busyState"
            v-on="$listeners"
          />
        </b-card>
      </div>
      <div></div>
    </div>
  </b-overlay>
</template>



<script>
import { BCard, BOverlay } from "bootstrap-vue";
import SearchFilingsTableNew from "./SearchFilingsTableNew";
import SearchFilingsTableFilters from "./SearchFilingsTableFilters";
export default {
  components: {
    BOverlay,
    SearchFilingsTableNew,
    SearchFilingsTableFilters,
    BCard,
  },
  props: {
    searchFilters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      totalRecords: 0,
      isBusy: true,
      basePerPage: 20,
      baseCurrentPage: 1,
      baseTotalRows: 0,
      basePageOptions: [5, 10, 20, 100],
      filters: [],
      newSearchFilters: this.searchFilters,
    };
  },

  created() {
    this.totalRecords = this.searchFilters.case_party_count;
    delete this.searchFilters.case_party_count;
  },

  methods: {
    searchBarSearch(searchValue) {
      this.$refs.searchFilingsTable.searchBarSearch(searchValue);
    },
    busyState(state) {
      this.isBusy = state;
    },
    // eslint-disable-next-line no-unused-vars
    updateFilters(nv) {
      this.filters = nv;
    },
  },
};
</script>

